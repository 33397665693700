<template>
    <v-tab v-bind="$attrs">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tab>
</template>

<script>
// import UTabSlider from "@/components/publish/styles/tabs/u-tab-slider.vue";

export default {
    props: {},
    components: {
        // UTabSlider,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
