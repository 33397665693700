<template>
    <u-tabs-slider v-model="TabsPrimaryValue" v-bind="$attrs" @change="TabsPrimaryValueChange">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-tabs-slider>
</template>

<script>
import UTabsSlider from "@/components/publish/styles/tabs/u-tabs-slider.vue";

export default {
    props: {
        value: { type: [Number, String] },
    },
    components: {
        UTabsSlider,
    },
    data() {
        return {
        };
    },
    computed: {
        TabsPrimaryValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    mounted() {},
    methods: {
        TabsPrimaryValueChange(newValue) {
            this.TabsPrimaryValue = newValue;
        },
    },
};
</script>

<style lang="scss" scoped></style>